import { ETenure } from "../enums/tenure";

export const TENURE_ITEM = {
  [ETenure.FREE_HOLD]: {
    id: ETenure.FREE_HOLD,
    name: "Freehold",
  },
  [ETenure.YR_99]: {
    id: ETenure.YR_99,
    name: "99 years",
  },
  [ETenure.YR_101]: {
    id: ETenure.YR_101,
    name: "101 years",
  },
  [ETenure.YR_999]: {
    id: ETenure.YR_999,
    name: "999 years",
  },
};
